<template>
    <div class="app flex-row align-items-center">
        <div class="container">
            <b-row class="justify-content-center">
                <b-col md="6">
                    <b-card-group>
                        <b-card no-body class="p-4">
                            <b-card-body>
                                <b-form @submit.prevent="login">
                                    <h1>Login</h1>
                                    <b-form-group :state="state.username" :invalid-feedback="validation.username[0]">
                                        <b-input-group :state="false">
                                            <b-input-group-prepend :state="false">
                                                <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                                            </b-input-group-prepend>
                                            <b-form-input :state="state.username" v-model="username" type="text"
                                                          placeholder="Gebruikersnaam"
                                                          name="username" autocomplete="username email"/>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group :state="state.password" :invalid-feedback="validation.password[0]">
                                        <b-input-group>
                                            <b-input-group-prepend>
                                                <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                                            </b-input-group-prepend>
                                            <b-form-input :state="state.password" v-model="password" type="password"
                                                          placeholder="Wachtwoord"
                                                          autocomplete="current-password"/>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-row>
                                        <b-col cols="6">
                                            <b-button variant="primary" class="px-4" type="submit">Login
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-card-group>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Login',
        data() {
            return {
                username: '',
                password: '',
                validation: {
                    username: [null],
                    password: [null]
                }
            }
        },
        mounted() {
            if(this.$auth.user()) {
                this.$router.push({name: 'dashboard'});
            }
        },
        computed: {
            state() {
                return {
                    username: typeof this.validation.username[0] === 'string' ? false : null,
                    password: typeof this.validation.password[0] === 'string' ? false : null
                }
            }
        },
        methods: {
            login() {
                this.validation = {
                    username: [null],
                    password: [null]
                };
                this.$auth.login(this.username, this.password).then(() => {
                    this.$router.push({name: 'dashboard'});
                    this.$toastr.s('Ingelogd!');
                }).catch((error) => {
                    if(error.response) {
                        if(error.response.status === 422) {
                            this.validation = error.response.data;
                        } else if(error.response.status === 401) {
                            this.validation = {
                                username: [null],
                                password: ['Incorrecte gebruikersnaam/wachtwoord combinatie.']
                            }
                        }
                    }
                });
            }
        }
    }
</script>
